var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalBase',{staticClass:"new-chart",attrs:{"closable":"","title":"Новый график"},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('Button',{staticClass:"new-chart__save",attrs:{"disabled":true},on:{"click":_vm.addChart}},[_vm._v("Добавить график")])]},proxy:true}])},[_c('div',{staticClass:"new-chart__content flex justify-between"},[_c('div',{staticClass:"new-chart__col"},[_c('div',{staticClass:"new-chart__item"},[_c('div',{staticClass:"new-chart__item-title"},[_vm._v("Доля полки")]),_c('Radio',{attrs:{"options":_vm.shelfShareOptions},on:{"input":_vm.changeOptions},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var option = ref.option;
return [_c('div',{staticClass:"new-chart__checkbox-title"},[_vm._v(_vm._s(option.title))]),(option.text)?_c('div',{staticClass:"new-chart__checkbox-text"},[_vm._v(_vm._s(option.text))]):_vm._e()]}}]),model:{value:(_vm.shelfShareModel),callback:function ($$v) {_vm.shelfShareModel=$$v},expression:"shelfShareModel"}})],1),_c('div',{staticClass:"new-chart__item"},[_c('div',{staticClass:"new-chart__item-title"},[_vm._v("Количество SKU")]),_c('Radio',{attrs:{"options":_vm.amountSKUOptions},on:{"input":_vm.changeOptions},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var option = ref.option;
return [_c('div',{staticClass:"new-chart__checkbox-title"},[_vm._v(_vm._s(option.title))]),(option.text)?_c('div',{staticClass:"new-chart__checkbox-text"},[_vm._v(_vm._s(option.text))]):_vm._e()]}}]),model:{value:(_vm.amountSKUModel),callback:function ($$v) {_vm.amountSKUModel=$$v},expression:"amountSKUModel"}})],1),_c('div',{staticClass:"new-chart__item"},[_c('div',{staticClass:"new-chart__item-title"},[_vm._v("Доступность SKU")]),_c('Radio',{attrs:{"options":_vm.availabilitySKUOptions},on:{"input":_vm.changeOptions},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var option = ref.option;
return [_c('div',{staticClass:"new-chart__checkbox-title"},[_vm._v(_vm._s(option.title))]),(option.text)?_c('div',{staticClass:"new-chart__checkbox-text"},[_vm._v(_vm._s(option.text))]):_vm._e()]}}]),model:{value:(_vm.availabilitySKUModel),callback:function ($$v) {_vm.availabilitySKUModel=$$v},expression:"availabilitySKUModel"}})],1)]),_c('div',{staticClass:"new-chart__col"},[_c('div',{staticClass:"new-chart__item"},[_c('div',{staticClass:"new-chart__item-title"},[_vm._v("Премиальная выдача у ритейлеров")]),_c('Radio',{attrs:{"options":_vm.premiumOptions},on:{"input":_vm.changeOptions},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var option = ref.option;
return [_c('div',{staticClass:"new-chart__checkbox-title"},[_vm._v(_vm._s(option.title))]),(option.text)?_c('div',{staticClass:"new-chart__checkbox-text"},[_vm._v(_vm._s(option.text))]):_vm._e()]}}]),model:{value:(_vm.premiumModel),callback:function ($$v) {_vm.premiumModel=$$v},expression:"premiumModel"}})],1),_c('div',{staticClass:"new-chart__item"},[_c('div',{staticClass:"new-chart__item-title"},[_vm._v("Премиальная выдача у ритейлеров")]),_c('Radio',{attrs:{"options":_vm.feedbackOptions},on:{"input":_vm.changeOptions},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var option = ref.option;
return [_c('div',{staticClass:"new-chart__checkbox-title"},[_vm._v(_vm._s(option.title))]),(option.text)?_c('div',{staticClass:"new-chart__checkbox-text"},[_vm._v(_vm._s(option.text))]):_vm._e()]}}]),model:{value:(_vm.feedbackModel),callback:function ($$v) {_vm.feedbackModel=$$v},expression:"feedbackModel"}})],1),_vm._l((_vm.list),function(item){return _c('div',{key:item.id,staticClass:"new-chart__item"},[_c('div',{staticClass:"new-chart__item-title"},[_vm._v("[API] "+_vm._s(item.groupName))]),_c('Radio',{attrs:{"options":_vm.list},on:{"input":_vm.changeOptions},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var option = ref.option;
return [_c('div',{staticClass:"new-chart__checkbox-title"},[_vm._v(_vm._s(option.name))]),(option.period)?_c('div',{staticClass:"new-chart__checkbox-text"},[_vm._v(_vm._s(option.period))]):_vm._e()]}}],null,true),model:{value:(_vm.listModel),callback:function ($$v) {_vm.listModel=$$v},expression:"listModel"}})],1)})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }